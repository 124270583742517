// @import 'components/mixins.scss';

// .ant-table-cell {
//   font-size: 1rem;
// }

// .ant-drawer-body {
//   background-color: $gray-1;
// }

// #nprogress .spinner {
//   display: none;
// }

@import 'components/mixins.scss';

#nprogress .spinner {
  display: none;
}

.ant-form-item {
  margin-bottom: 14px !important;
}

.ant-form-item-label {
  padding: 0 0 2px 0 !important;
}

.ant-table-cell {
  font-size: 1rem;
}

.ant-drawer-body {
  background-color: $gray-1;
}
